<template>
  <div class="index">
    <section>
      <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
          <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="6"></li>
        </ol>
        <div class="carousel-inner">
          <!-- <div class="carousel-item active">
            <div class="video-container">
              <iframe
                src="https://www.youtube.com/embed/cw-IpPnm-Is?si=Ysd0NMNvJdd-Azl3"
                title="台英鐵道"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>

              <video controls autoplay class="video">
                <source
                    src="../assets/video/the_railway_journey_vodeo_45.mp4" type="video/mp4"
                />
                <object data="../assets/video/the_railway_journey_vodeo_45.mp4">
                    <embed src="../assets/video/the_railway_journey_vodeo_45.mp4" />
                </object>
                </video>
              <div v-show="playBtnState" class="play-btn" @click="playVideo">
              ▶
              </div>
          </div> -->
          <div class="carousel-item active">
            <img src="../assets/imgs/index_img_carousel_01.jpg" class="d-block w-100" alt="...">
          </div>

          <div class="carousel-item">
            <img src="../assets/imgs/index_img_carousel_02.jpg" class="d-block w-100" alt="...">
          </div>
          <div class="carousel-item">
            <img src="../assets/imgs/index_img_carousel_03.jpg" class="d-block w-100" alt="...">
          </div>
          <div class="carousel-item">
            <img src="../assets/imgs/index_img_carousel_04.jpg" class="d-block w-100" alt="...">
          </div>
          <div class="carousel-item">
            <img src="../assets/imgs/index_img_carousel_05.jpg" class="d-block w-100" alt="...">
          </div>
          <div class="carousel-item">
            <img src="../assets/imgs/index_img_carousel_06.jpg" class="d-block w-100" alt="...">
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-target="#carouselExampleIndicators" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-target="#carouselExampleIndicators" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </button>
      </div>

      <!-- <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="4000"
        controls
        indicators
        background="#ababab"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333;"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >

        <b-carousel-slide
          img-src="../assets/imgs/index_img_carousel_01.jpg"
        >      
        </b-carousel-slide>
        <b-carousel-slide
          img-src="../assets/imgs/index_img_carousel_02.jpg"
        >      
        </b-carousel-slide>
        <b-carousel-slide
          img-src="../assets/imgs/index_img_carousel_03.jpg"
        >      
        </b-carousel-slide>
        <b-carousel-slide
          img-src="../assets/imgs/index_img_carousel_04.jpg"
        >      
        </b-carousel-slide>
        <b-carousel-slide
          img-src="../assets/imgs/index_img_carousel_05.jpg"
        >      
        </b-carousel-slide>
        <b-carousel-slide
          img-src="../assets/imgs/index_img_carousel_06.jpg"
        >      
        </b-carousel-slide>


      </b-carousel> -->
    </section>

    <section class="lightArea">
      <b-container>
        <h2 class="indexSubTitle">{{ $t("HomeVideoTitle") }}</h2>
        <b-row class="justify-content-center">
          <b-col>
            <div class="video-container">
              <iframe
                src="https://www.youtube.com/embed/_0D6SSS6NEc?si=DSOoZn7V0uEu8PAo"
                title="2024「牽手762的約定」阿里山林鐵DL-34機關車台英交流大使 / A story that passes through 9971KM"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </b-col>
        </b-row>
      </b-container>

      <b-container class="pt-5">
        <h2 class="indexSubTitle">{{ $t("HomeVideoTitle2") }}</h2>
        <b-row class="justify-content-center">
          <b-col>
            <div class="video-container">
              <iframe
                src="https://www.youtube.com/embed/cw-IpPnm-Is?si=jKYKxZtA_Uyridl9"
                title="飛越9971的情誼：阿里山火車的遠航故事 /TWIN GAUGE: The Story of The Alishan Forest Railway Journey"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </b-col>
        </b-row>
      </b-container>




    </section>

    <section class="darkArea">
      <b-container>
        <b-row cols="1" cols-sm="1" cols-md="2" cols-lg="2">
          <b-col align-self="center" class="px-md-5" data-aos="fade-up">
            <h2 class="indexSubTitle">{{ $t("TopNav01") }}</h2>
            <p>{{ $t("TheSequel_01") }}</p>
            <div class="d-flex justify-content-end">
              <b-button to="TheSequel" @click="scrollToTop" class="moreBtn btn btn-round drop-shadow">{{ $t("more") }}</b-button>
            </div>
          </b-col>
          <b-col align-self="center" data-aos="fade-up">
            <b-img :src="require('../assets/imgs/img_TheSequel_01.jpg')" fluid class="drop-shadow" alt="" ></b-img>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="lightArea">
      <b-container>
        <b-row cols="1" cols-sm="1" cols-md="2" cols-lg="2" data-aos="fade-up">
          <b-col align-self="center" order="2" order-md="1" order-lg="1">
            <b-img :src="require('../assets/imgs/img_MovingLandscapes_Station_Zhangnaoliao_2.jpg')" fluid class="drop-shadow" alt="" ></b-img>
          </b-col>
          <b-col align-self="center" class="px-md-5" order="1" order-md="2" order-lg="2">
            <h2 class="indexSubTitle">{{ $t("TopNav02") }}</h2>
            <p>{{ $t("Moving_01") }}</p>
            <p>{{ $t("Moving_02") }}</p>
            <div class="d-flex justify-content-end">
              <b-link to="MovingLandscapes" @click.native="scrollToTop" class="moreBtn btn btn-round drop-shadow">{{ $t("more") }}</b-link>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <b-container fluid>
        <div class="swiper-demo">
          <div class="swiper">
            <div class="swiper-pagination"></div>
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <p class="text-shadow">{{ $t("Station_Chiayi2") }}</p>
                <img src="../assets/imgs/img_MovingLandscapes_Station_Chiayi_1.jpg" alt="img-1" />
              </div>
              <div class="swiper-slide">
                <p class="text-shadow">{{ $t("Station_Beimen2") }}</p>
                <img src="../assets/imgs/img_MovingLandscapes_Station_Beimen_1.jpg" alt="img-1" />
              </div>
              <div class="swiper-slide">
                <p class="text-shadow">{{ $t("Station_Lumachan2") }}</p>
                <img src="../assets/imgs/img_MovingLandscapes_Station_Lumachan_1.jpg" alt="img-1" />
              </div>
              <div class="swiper-slide">
                <p class="text-shadow">{{ $t("Station_Zhuqi2") }}</p>
                <img src="../assets/imgs/img_MovingLandscapes_Station_Zhuqi_1.jpg" alt="img-1" />
              </div>
              <div class="swiper-slide">
                <p class="text-shadow">{{ $t("Station_Mululiao2") }}</p>
                <img src="../assets/imgs/img_MovingLandscapes_Station_Mululiao_1.jpg" alt="img-1" />
              </div>
              <div class="swiper-slide">
                <p class="text-shadow">{{ $t("Station_Zhangnaoliao2") }}</p>
                <img src="../assets/imgs/img_MovingLandscapes_Station_Zhangnaoliao_1.jpg" alt="img-1" />
              </div>
              <div class="swiper-slide">
                <p class="text-shadow">{{ $t("Station_Dulishan2") }}</p>
                <img src="../assets/imgs/img_MovingLandscapes_Station_Dulishan_2.jpg" alt="img-1" />
              </div>
              <div class="swiper-slide">
                <p class="text-shadow">{{ $t("Station_Liyuanliao2") }}</p>
                <img src="../assets/imgs/img_MovingLandscapes_Station_Liyuanliao_1.jpg" alt="img-1" />
              </div>
              <div class="swiper-slide">
                <p class="text-shadow">{{ $t("Station_Jiaoliping2") }}</p>
                <img src="../assets/imgs/img_MovingLandscapes_Station_Jiaoliping_1.jpg" alt="img-1" />
              </div>
              <div class="swiper-slide">
                <p class="text-shadow">{{ $t("Station_Shuisheliao2") }}</p>
                <img src="../assets/imgs/img_MovingLandscapes_Station_Shuisheliao_1.jpg" alt="img-1" />
              </div>
              <div class="swiper-slide">
                <p class="text-shadow">{{ $t("Station_Fenqihu2") }}</p>
                <img src="../assets/imgs/img_MovingLandscapes_Station_Fenqihu_1.jpg" alt="img-1" />
              </div>
              <div class="swiper-slide">
                <p class="text-shadow">{{ $t("Station_Duolin2") }}</p>
                <img src="../assets/imgs/img_MovingLandscapes_Station_Duolin_1.jpg" alt="img-1" />
              </div>
              <div class="swiper-slide">
                <p class="text-shadow">{{ $t("Station_Shizilu2") }}</p>
                <img src="../assets/imgs/img_MovingLandscapes_Station_Shizilu_1.jpg" alt="img-1" />
              </div>
              <div class="swiper-slide">
                <p class="text-shadow">{{ $t("Station_Pingzhena") }}</p>
                <img src="../assets/imgs/img_MovingLandscapes_Station_Pingzhena_1.jpg" alt="img-1" />
              </div>
              <div class="swiper-slide">
                <p class="text-shadow">{{ $t("Station_Erwanping2") }}</p>
                <img src="../assets/imgs/img_MovingLandscapes_Station_Erwanping_1.jpg" alt="img-1" />
              </div>
              <div class="swiper-slide">
                <p class="text-shadow">{{ $t("Station_Shenmu2") }}</p>
                <img src="../assets/imgs/img_MovingLandscapes_Station_Shenmu_1.jpg" alt="img-1" />
              </div>
              <div class="swiper-slide">
                <p class="text-shadow">{{ $t("Station_Alishan2") }}</p>
                <img src="../assets/imgs/img_MovingLandscapes_Station_Alishan_1.jpg" alt="img-1" />
              </div>
              <div class="swiper-slide">
                <p class="text-shadow">{{ $t("Station_Zhaoping2") }}</p>
                <img src="../assets/imgs/img_MovingLandscapes_Station_Zhaoping_1.jpg" alt="img-1" />
              </div>
              <div class="swiper-slide">
                <p class="text-shadow">{{ $t("Station_Duigaoyue2") }}</p>
                <img src="../assets/imgs/img_MovingLandscapes_Station_Duigaoyue_1.jpg" alt="img-1" />
              </div>
              <div class="swiper-slide">
                <p class="text-shadow">{{ $t("Station_Zhushan2") }}</p>
                <img src="../assets/imgs/img_MovingLandscapes_Station_Zhushan_1.jpg" alt="img-1" />
              </div>
            </div>

            
            <!-- <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div> -->
            <div class="swiper-scrollbar"></div>
          </div>
        </div>
        
      </b-container>
    </section>

    <section class="darkArea">
      <b-container>
        <b-row cols="1" cols-sm="1" cols-md="2" cols-lg="2" data-aos="fade-up">
          <b-col align-self="center" class="px-md-5">
            <h2 class="indexSubTitle">{{ $t("TopNav03") }}</h2>
            <p>{{ $t("Reconstructing_01") }}</p>
            <div class="d-flex justify-content-end">
              <b-button to="Reconstructing"  @click="scrollToTop" class="moreBtn btn btn-round drop-shadow">{{ $t("more") }}</b-button>
            </div>
          </b-col>
          <b-col align-self="center">
            <b-img :src="require('../assets/imgs/img_Reconstructing_1.jpg')" fluid class="drop-shadow" alt="" ></b-img>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="lightArea">
      <b-container>
        <b-row cols="1" cols-sm="1" cols-md="2" cols-lg="2" data-aos="fade-up">
          <b-col align-self="center" order="2" order-md="1" order-lg="1">
            <b-img :src="require('../assets/imgs/img_Shared_01.jpg')" fluid class="drop-shadow" alt="" ></b-img>
          </b-col>
          <b-col align-self="center" class="px-md-5" order="1" order-md="2" order-lg="2">
            <h2 class="indexSubTitle">{{ $t("TopNav04") }}</h2>
            <h4>{{ $t("Shared_01") }}</h4>
            <p>{{ $t("Shared_02") }}</p>
            <div class="d-flex justify-content-end">
              <b-button to="Shared"  @click="scrollToTop" class="moreBtn btn btn-round drop-shadow">{{ $t("more") }}</b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="darkArea">
      <b-container>
        <b-row cols="1" cols-sm="1" cols-md="2" cols-lg="2" data-aos="fade-up">
          <b-col align-self="center" class="px-md-5">
            <h2 class="indexSubTitle">{{ $t("TopNav05") }}</h2>
            <p>{{ $t("Journey_01") }}</p>
            <div class="d-flex justify-content-end">
              <b-button to="Journeys" @click="scrollToTop" class="moreBtn btn btn-round drop-shadow mt-5">{{ $t("more") }}</b-button>
            </div>
          </b-col>
          <b-col align-self="center">
            <b-img :src="require('../assets/imgs/img_Journeys_13.jpg')" fluid class="drop-shadow" alt="" ></b-img>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <div id="myMenu">
      <a href="javascript:" v-scroll-to="'#top'" :title="$t('btop')"
        ><span class="icon-arrow_up"></span
        ><span class="sr-only">to top</span></a
      >
    </div>
  </div>
</template>

<script>

import Swiper from "swiper";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/swiper-bundle.css";
Swiper.use([Navigation,Autoplay, Pagination]);

export default {
  name: "Home", 
  components: {
  },
  mounted() {

    new Swiper(".swiper", {
        loop: false,
        autoplay:true,
        slidesPerView: 1,
        spaceBetween: 24,
        speed: 2000,
        breakpoints: {
          768: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
          1024: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
        },
        pagination: {
          el: ".swiper-pagination",
        },
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
        scrollbar: {
          el: ".swiper-scrollbar",
        },
      });
  },
};
</script>
