<script>
//import API from "../api/API";
export default {
  name: "Mixin",
  data() {
    return {
      userSignInData: {
        user_account: "",
        user_pwd: "",
      },
      userInfo: null,
      TokenID: "",
      Token: "",
      navBarActive: false,
      // userRequest: this.$axios.create({ baseURL: "../api" }), // 本機端
      userRequest: this.$axios.create({ baseURL: "../API" }), // 正式機
      apiToken: (data) => this.userRequest.post("API/Default/Token", data),
    };
  },
  created() {  
  },
  mounted() {    
  },
  computed: {
  },
  methods: {
    date2str(date) {
      //西元轉民國
      return [
        date.getFullYear(),
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1),
        date.getDate() > 9 ? date.getDate() : "0" + date.getDate(),
      ];
    },
    FunctionToken: function (EXfunction, data_in) {
      this.apiToken({})
        .then((res) => {
          data_in.TokenID = this.TokenID;
          data_in.Token = res.data;
          EXfunction(data_in);
        })
        .catch((error) => {
          alert("發生錯誤!" + error);
        });
    },
    _sessionData(key, val = undefined) {
      if (val == undefined) {
        return sessionStorage.getItem(key);
      } else if (val == "") {
        sessionStorage.removeItem(key);
      } else {
        sessionStorage.setItem(key, val);
      }
    },
    IsNull(data) {
      if (data == "" || data == null || data == undefined) {
        return "";
      } else {
        return data;
      }
    },
    _go2(json, type = "params") {
      /*
        {
            name:'test1',
            path:'/test1',
            data:{},
            key:'www',
        }
        */
      if (type == "params") {
        if (this.IsNull(json.key) != "") {
          return this.$route.params[json.key];
        } else {
          this.$router.push({
            name: json.name,
            params: json.data,
          });
        }
      } else if (type == "query") {
        if (this.IsNull(json.key) != "") {
          return this.$route.query[json.key];
        } else {
          this.$router.push({
            path: json.path,
            query: json.data,
          });
        }
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0); 
      this.navBarActive=false;         
    },
  },  
};
</script>

