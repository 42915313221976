<template>
  <div id="app">
    <a name="top" id="top"><span class="sr-only">top</span></a>
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
export default {
  name: 'App',
  components: {
    Header, Footer
  },
  mounted() {
    // 禁用右鍵功能
    document.addEventListener('contextmenu', (e) => e.preventDefault());
  },
  beforeUnmount() {
    // 確保組件卸載時移除事件監聽，避免記憶體洩漏
    document.removeEventListener('contextmenu', (e) => e.preventDefault());
  }
}
</script>
