import Vue from "vue";
import VueRouter from "vue-router";
import { BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import Home from '../views/Home';
import Home_old from '../views/Home_old';
import MovingLandscapes from '../views/MovingLandscapes';
import TheSequel from '../views/TheSequel';
import Reconstructing from '../views/Reconstructing';
import Shared from '../views/Shared.vue'
import Journeys from '../views/Journeys.vue'


Vue.use(VueRouter);
Vue.use(BootstrapVueIcons)
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/",
    name: "Home_old",
    component: Home_old,
  },
  {
    path: "/MovingLandscapes",
    name: "MovingLandscapes",
    component: MovingLandscapes,
  },
  {
    path: "/TheSequel",
    name: "TheSequel",
    component: TheSequel,
  },
  {
    path: "/Reconstructing",
    name: "Reconstructing",
    component: Reconstructing,
  },
  {
    path: "/Shared",
    name: "Shared",
    component: Shared,
  },
  {
    path: "/Journeys",
    name: "Journeys",
    component: Journeys,
  }
];


const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;