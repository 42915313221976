// i18n.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import EN from './lang/en.json' // 英文
import ZH from './lang/zh.json' // 繁體中文

Vue.use(VueI18n)

let browserLanguage = "ZH"
//window.navigator.language

let userSetLanguage = sessionStorage.getItem("language");

// 先塞入瀏覽器預設語言在 i18n
if (userSetLanguage == null) {
    if(browserLanguage!=null){
        let firstStr = browserLanguage.split('-')[0];
        switch (firstStr) {
            case 'en':
                browserLanguage = 'EN';
                sessionStorage.setItem("language","EN");
                break;
            case 'zh':
                browserLanguage = 'ZH';
                sessionStorage.setItem("language","ZH");
                break;
            default:
                browserLanguage = 'ZH';
                sessionStorage.setItem("language","ZH");
                break;
        }
    }
    else{
        browserLanguage = 'ZH';
        sessionStorage.setItem("language","ZH");
    }
} else {
    browserLanguage = userSetLanguage;
}


// 存放語系 json 
const messages = {
    EN: EN,
    ZH: ZH
}

export const i18n = new VueI18n({
    locale: browserLanguage, // set locale
    fallbackLocale: browserLanguage,
    messages // set locale messages
})